require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("bootstrap");
import './shared_landing';

const feather = require("feather-icons");

document.addEventListener("turbolinks:load", function() {
    feather.replace();
})
