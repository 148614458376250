import 'flickity';
import 'flickity-imagesloaded';
import 'flickity-as-nav-for';
import 'flickity-fade';
import '../../../node_modules/flickity/dist/flickity.min.css';
import '../../../node_modules/flickity-fade/flickity-fade.css';

import './shared_landing/aos';
import './shared_landing/countup';
import './shared_landing/map';
import './shared_landing/navbar';
import './shared_landing/navbar-dropdown';
import './shared_landing/collapse';
