//
// aos.js
// Theme module
//

import AOS from 'aos';
require("aos/dist/aos.css")

document.addEventListener("turbolinks:load", function() {

  const options = {
    duration: 700,
    easing: 'ease-out-quad',
    once: true,
    startEvent: 'load'
  };
  AOS.init(options);

});
