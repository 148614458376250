//
// map.js
// Theme module
//
import mapboxgl from '!mapbox-gl';
require("mapbox-gl/dist/mapbox-gl.css")

  document.addEventListener("turbolinks:load", function() {

  const maps = document.querySelectorAll('[data-map]');
  const accessToken = 'pk.eyJ1IjoiZnJhbXZlbnR1cmUiLCJhIjoiY2tseHRraTdwMjNtOTJ5bXA4eXd4a3o4NCJ9.FRJ3AIh5tfT1ZxrQyiFzpA';

  maps.forEach(map => {
    const elementOptions = map.dataset.map;

    const defaultOptions = {
      container: map,
      style: 'mapbox://styles/mapbox/light-v9',
      scrollZoom: false,
      interactive: false
    };

    const options = {
      ...defaultOptions,
      ...elementOptions
    };

    // Get access token
    mapboxgl.accessToken = accessToken;

    // Init map
    new mapboxgl.Map(options);
  });

});
